import React from 'react'
import { Form, Button } from 'react-bootstrap'
import Icon from '~components/Icon'
import * as css from './Form.module.scss'

export const SubcribeForm = ({ step, onSubmit }) => {
  const [validated, setValidated] = React.useState(false)

  const onSubmitHandler = async (event) => {
    event.preventDefault()

    const form = event.currentTarget
    const isValid = !!form.checkValidity()

    if (!isValid) {
      event.stopPropagation()
    }

    setValidated(true)

    if (isValid) {
      onSubmit && (await onSubmit(event))
    }
  }
  return (
    <div className={css.root}>
      <h2 className={css.title}>{step.title}</h2>
      <p className={css.description}>{step.text}</p>
      <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
        <Form.Group className={css.inputRoot} controlId="subscribe-email">
          <Form.Control className={css.input} type="email" placeholder="Your Email" required />
          <div className={css.buttonRoot}>
            <Button className={css.submitButton} type="submit">
              <Icon name="arrow" size={28} />
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}
