import React from 'react'
import * as css from './ThankYou.module.scss'

export const ThankYou = ({ step }) => {
  return (
    <div>
      <h2 className={css.title}>{step.title}</h2>
      <p className={css.description}>{step.text}</p>
    </div>
  )
}
