import React from 'react'
import { Button } from 'react-bootstrap'
import * as css from './Tiers.module.scss'

export const Tiers = ({ title, text, video, button }) => {
  return (
    <section id="tiers" className={css.root}>
      <div>
        <video src={video} className={css.video} loop autoPlay muted playsInline></video>
      </div>
      <div className={css.text}>
        <h2>{title}</h2>
        <p>{text}</p>
        <div>
          <Button className={css.button} {...button} />
        </div>
      </div>
    </section>
  )
}
