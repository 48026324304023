import React from 'react'
import { Button } from 'react-bootstrap'
import * as css from './Hero.module.scss'

export const Hero = ({ title, text, video, button }) => {
  return (
    <section className={css.root}>
      <div className={css.text}>
        <h1>{title}</h1>
        <p>{text}</p>
        <div>
          <Button className={css.button} {...button} />
        </div>
      </div>
      <div>
        <video src={video} className={css.video} loop autoPlay muted playsInline></video>
      </div>
    </section>
  )
}
