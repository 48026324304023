import React, { useState } from 'react'
import { SubcribeForm } from './Form'
import { ThankYou } from './ThankYou'
import * as css from './Subscribe.module.scss'

export const Subscribe = ({ firstStep, secondStep }) => {
  const [step, setStep] = useState('firstStep')

  const onSubmitHandler = () => {
    setStep('secondStep')
  }

  return (
    <section id="subscribe" className={css.root}>
      {step === 'firstStep' && <SubcribeForm step={firstStep} onSubmit={onSubmitHandler} />}
      {step === 'secondStep' && <ThankYou step={secondStep} onSubmit={onSubmitHandler} />}
    </section>
  )
}
