import * as React from 'react'
import Layout from '../components/Layout'
import S from '../components/seo'

import { Hero } from '~components/MainPage/Hero/Hero'
import { Tiers } from '~components/MainPage/Tiers/Tiers'
import { Background } from '~components/MainPage/Background/Background'

import { Container } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { Subscribe } from '~components/MainPage/Subscribe/Subscribe'

const IndexPage = ({ data }) => {
  const { hero, tiers, subscribe } = data.dataJson.mainPage

  return (
    <Background>
      <Layout>
        <Container>
          <S title="Home" />
          <Hero {...hero} />
          <Tiers {...tiers} />
          <Subscribe {...subscribe} />
        </Container>
      </Layout>
    </Background>
  )
}

export const query = graphql`
  {
    dataJson {
      mainPage {
        hero {
          title
          text
          video
          button {
            href
            children
          }
        }
        tiers {
          title
          text
          video
          button {
            href
            children
          }
        }
        subscribe {
          firstStep {
            title
            text
          }
          secondStep {
            title
            text
          }
        }
      }
    }
  }
`

export default IndexPage
